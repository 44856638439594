.download-main-tvfebracis {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;

    .container-download {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

       .download-top {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 292px;
            height: 305px;
            background-image: url('../../assets/img/DownloadBg.svg');

            @media (min-width: 1080px) {
                width: 505px;
                height: 414.5px;
                background-repeat: no-repeat;
                background-size: 100%;
            }

            .download-title {
                display: flex;
                height: 120px;
                justify-content: space-between;
                width: 242px;

                @media (min-width: 1080px) {
                    width: 380px;
                }

                h1 {
                    margin-top: 66px;
                    color: #FFF;
                    font-family: 'Poppins', sans-serif;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 35px;

                    @media (min-width: 1080px) {
                        width: 184px;
                        height: 31px;
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 35px;
                        margin-top: 130px;
                    }
                }

                svg {
                    margin-top: 28px;

                    @media (min-width: 1080px) {
                        margin-top: 43px;
                        width: 29.25px;
                        height: 78.75px;
                        flex-shrink: 0;
                    }
                }
            }

            p {
                margin: 0 25px 0 25px;
                color: #FFF;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-size: 15px;
                font-weight: 500;
                line-height: 18px;

                @media (min-width: 1080px) {
                    margin: 85px 58px 0 67px !important;

                    display: flex;
                    width: 380px;
                    height: 124px;
                    flex-direction: column;
                    justify-content: center;

                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25px;
                }
            }
        }

        .download-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 292px;
            height: 217.972px;
            border-radius: 16px;
            background: #FFF;
            margin-top: -45px;

            @media (min-width: 1080px) {
                width: 505px;
                height: 296px;
            }

            .download-app {
                color: #050647;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 25px;
                margin-top: 23px;

                @media (min-width: 1080px) {
                    display: flex;
                    width: 224px;
                    height: 30px;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 20px;
                    line-height: 25px;
                }
            }

            .download-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 20px;
                gap: 18px;

                @media (min-width: 1080px) {
                    margin-top: 27px;
                    gap: 24px;
                }

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 208.677px;
                    height: 46.455px;
                    border-radius: 16px;
                    border: none;

                    @media (min-width: 1080px) {
                        width: 377px;
                        height: 63px;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                    
                        span {
                            display: flex;
                            width: 123.879px;
                            height: 26.545px;
                            flex-direction: column;
                            justify-content: center;

                            color: #FFF;
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 25px;

                            @media (min-width: 1080px) {
                                display: flex;
                                width: 168px;
                                height: 36px;
                                
                                font-size: 24px;
                                font-weight: 500;
                                line-height: 25px;
                            }
                        }
                    }
                }

                .btn-android {
                    background: #3E3E3E;
                }

                .btn-apple {
                    background: #185CA1;
                }
            }
        }
    }
}

.isMobile {
    width: 100%;
    margin-top: 24px;
    .container-download {
        align-items: center;
    }
}
