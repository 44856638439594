// Import
@import '../../assets/css/index';

// Init
.download-main {
    padding: 55px 0;
    // margin-top: 100px;
    position: relative;

    @media (max-width: 990px) and (orientation: landscape) {
        margin-top: 0;
    }

    @media(min-width: 990px) {
        padding: 0px 0;
    }
}

.download-text {
    @include displayFlex(row, wrap, center, center, center);
    
    @media(min-width: 990px) {
        justify-content: space-around;
    }
}

.download-content {
    text-align: center;

    h2 {
        margin: 0;

        font-family: 'Montserrat';
        font-size: 35px;
        color: #737A8F;

        b {
            font-weight: 800;
        }

        @media(min-width: 400px) {
            font-size: 48px;  
        }
    }

    h4 {
        margin: 0;

        font-size: 20px;
        font-weight: normal;
        color: #737A8F;
    }
}

.download-button {
    margin-top: 30px;
    @include displayFlex(column, wrap, center, center, center);

    @media(min-width: 400px) {
        flex-flow: row wrap;
    }

    button {
        padding: 8px 20px;
        
        overflow: hidden;
        position: relative;

        border: 1px solid #737A8F;
        border-radius: 8px;

        transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1) 0s;

        cursor: pointer;

        span {
            margin-left: 8px;
            
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 15px;
        }

        &:hover {
           transform: scale(1.05);
        }

        &:active,
        &:focus,
        &:hover {
            outline: none;
        }

        a {
            @include displayFlex(row, wrap, center, center, center);

            text-decoration: none;
        }
    }
}

.btn-windows {
    margin-bottom: 20px;
    background: transparent;
    
    span {
        color: #737A8F;
    }
    
    @media(min-width: 400px) {
        margin-bottom: 0;
        margin-right: 30px;
    }
}

.btn-apple {
    border: 0px;
    background: #737A8F;

    span {
        color: #fff;
    }
}

// image
.download-image {
    // display: none;
    position: relative;
    top: 43px;

    @media(min-width: 990px) {
        display: block;
    }
}