* {
    &,
    &:before,
    &:after {
        box-sizing: border-box;
    }
}

// Imagens
img {
    max-width: 100%;
    height: auto;
}

// List
ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

li {
    list-style: none;
}

// Container
.container {
    width: $widthContainer;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
}