
html,
body {
    height: 100%;
}

#root,
.App {
    height: 100%;
}

.App {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
    flex-flow: column nowrap;
}