// VARIABLES //

// ** Container
$widthContainer: 1400px;

// MIXINS //

// ** Display Flex
@mixin displayFlex($direction, $wrap, $justify, $item, $content) {
    display: flex;
    flex-flow: $direction $wrap;
    justify-content: $justify;
    align-items: $item;
    align-content: $content;
}

// ** Font

@mixin fontDisplay($family, $size, $weight,$color) {
    font-family: $family;
    font-size: $size;
    font-weight: $weight;
    color: $color;
}

// ** Animation 
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
      @content; 
    }
    @-moz-keyframes #{$name} {
      @content;
    }
    @-ms-keyframes #{$name} {
      @content;
    }
    @keyframes #{$name} {
      @content;
    } 
  }


