* {
    padding: 0;
    margin: 0;
}

body, 
html {
    overflow-x: hidden;
    font-family: 'Poppins', 'Open Sans', sans-serif;
}
