// Import
@import '../../assets/css/index';

// Init
.footer-main {
    width: 100%;
    padding: 10px 0;

    background: #FDFBFA;
}

.footer-container {
    position: relative;
    @include displayFlex(column, wrap, space-between, center, center);
    padding-top: 0;
}

// Social
.footer-social {
    @include displayFlex(row, wrap, space-between, center, center);

}

.icon-facebook {
    margin-right: 10px;
}

.icon-facebook,
.icon-instagram {
    transition: all 0.2s cubic-bezier(0.42, 0, 0.58, 1) 0s;
    cursor: pointer;

    &:hover {
        transform: scale(1.18);
    }
}

// Copyright
.footer-copyright {
    margin: 0 auto;

    p {
        margin: 0;

        font-size: 14px;
        font-weight: 400;
        color: #7E859A;
        text-align: center;
    }
}

//Arrow
.footer-arrow {
    width: 70px;
    height: 70px;
    position: absolute;
    top: -30px;
    right: 50%;
    transform: translateX(50%); 
    @include displayFlex(row, wrap, center, center, center);
    
    background: #BFC1C9;
    border: 5px solid #FDFBFA;
    border-radius: 100%;
    
    @media(min-width: 750px) {
        right: 0;
        transform: translateX(0%); 
    }
}

.icon-arrow-top {
    cursor: pointer;
    animation: bounce 2s infinite;
}

@include keyframes(bounce) {
	0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
  }
	40% {
        transform: translateY(-8px);
  }
	60% {
        transform: translateY(-4px);
  }
}
