.new-home-body {
    width: 100%;
    height: 100%;
    background: linear-gradient( to bottom, #fff 50%, #f4f4f4 50%, ) !important;

    .home-body-container {
        display: flex;
        column-gap: 65px;
        width: 100%;

        .download {
            width: 50%;
        }

        .tv-febracis {
            width: 50%;
            display: flex;
            justify-content: flex-start;

            img {

                @media (min-width: 800px) {
                    width: 380px;
                }

                @media (min-width: 1080px) {
                    width: 520px;
                }
            }
        }
    }
}