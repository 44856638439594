// Import
@import '../../assets/css/index';

// Init

.header-main {
    width: 100%;
    position: relative;

    background: url('../../assets/img/header-bg-violet.png') top right no-repeat;
    background-size: contain;
}

.header-content {
    width: 100%;
    @include displayFlex(row, nowrap, center, center, center);
    padding-top: 40px;
}

.effect-yellow {
    width: 100%;
    height: 70%;
    position: absolute;
    bottom: 0;
    left: 0;

    background: url('../../assets//img/header-bg-yellow.png') bottom left no-repeat;
    background-size: contain;
}